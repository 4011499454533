import $ from 'jquery'
import 'jquery-formatcurrency'
import { onPageLoad } from '~/common/extensions/dom_ready'
import accounting from 'accounting'

$.formatCurrency.regions[''] = {
  positiveFormat: '%s %n',
  negativeFormat: '-%s %n',
  roundToDecimalPlace: 2,
  symbol: '$',
  decimalSymbol: '.',
  digitGroupSymbol: ',',
  groupDigits: true,
}

// Now using accounting.js as well
accounting.settings = {
  currency: {
    symbol: '$',
    format: {
      pos: '%s %v',
      neg: '-%s %v',
      zero: '%s %v',
    },
    decimal: '.',
    thousand: ',',
    precision: 2,
  },
  number: {
    precision: 0,
    thousand: ',',
    decimal: '.',
  },
}

onPageLoad('default account currency', function () {
  let left, left1
  $.formatCurrency.regions[''].symbol =
    (left = $('body').data('currency')) != null ? left : '$'
  accounting.settings.currency.symbol =
    (left1 = $('body').data('currency')) != null ? left1 : '$'
})

$.fn.formatCurrencyZen = function () {
  return this.each(function () {
    const $this = $(this)
    if ($this.val().match(/\d,\d+?$/)) {
      $this.val($this.val().replace(',', '.'))
    }
    let decimals = $this.attr('data-currency-decimals') || 2
    $this.formatCurrency({
      symbol: '',
      positiveFormat: '%n',
      negativeFormat: '-%n',
      roundToDecimalPlace: decimals,
    })
  })
}

const exclVatField = (elm) =>
  $(elm)
    .closest('.form-group,.form-group-row,.currency-field-wrapper')
    .find('input.excl_tax')

const inclVatField = (elm) =>
  $(elm)
    .closest('.form-group,.form-group-row,.currency-field-wrapper')
    .find('input.incl_tax')

$.fn.cents = function (cents) {
  return this.each(function () {
    const $this = $(this)
    const $incl = inclVatField($this)
    const $excl = exclVatField($this)
    const vat_factor = 1 + parseFloat($incl.data('tax-percent')) / 100.0

    $this.val(cents)
    let decimals = $this.attr('data-currency-decimals') || 2
    $excl.val(cents / 10 ** decimals).formatCurrencyZen()
    $incl.val((cents / 10 ** decimals) * vat_factor).formatCurrencyZen()
  })
}

window.onDomChanged('.cents_field', function () {
  const $this = this
  const $excl = exclVatField($this)
  const $incl = inclVatField($this)

  const taxPercent = $incl.data('tax-percent') || $this.data('tax-percent')
  const vat_factor = 1 + parseFloat(taxPercent) / 100

  $incl.on('keyup change', function () {
    let decimals = $(this).attr('data-currency-decimals') || 2
    const cents = Math.round(($(this).asNumber() * 10 ** decimals) / vat_factor)
    $this.val(cents).change()
    $excl.val(cents / 10 ** decimals).formatCurrencyZen()
  })

  $excl.on('keyup change', function () {
    let decimals = $(this).attr('data-currency-decimals') || 2
    const cents = Math.round($(this).asNumber() * 10 ** decimals)
    $this.val(cents).change()
    if ($incl) {
      $incl.val((cents / 10 ** decimals) * vat_factor).formatCurrencyZen()
    }
  })

  $excl.on('blur', function () {
    let decimals = $(this).attr('data-currency-decimals') || 2
    $(this).val($this.val() / 10 ** decimals)
  })

  $incl.on('blur', function () {
    let decimals = $(this).attr('data-currency-decimals') || 2
    $(this).val(($this.val() / 10 ** decimals) * vat_factor)
  })
})

window.onDomChanged('.currency_field', function () {
  const self = this
  this.formatCurrencyZen()
    .blur(function () {
      $(this).formatCurrencyZen()
    })
    .focus(function () {
      let decimals = $(this).attr('data-currency-decimals') || 2
      $(this)
        .val(accounting.formatNumber($(this).asNumber(), decimals, '', '.'))
        .select()
    })
  const currency_input_name = this.data('currency-input-name')
  if (currency_input_name != null) {
    this.closest('form')
      .find(`input[name='${currency_input_name}']`)
      .on('change', function (e) {
        const value = $(this).val()
        self
          .closest('.input-group')
          .find('.input-group-addon.prepend span')
          .html(value)
      })
  }
  if (self.data('dirty-tracking')) {
    $(this).data('initial-input-value', $(this).val())
  }
})

window.onDomChanged('.select_currency', function (e) {
  const self = this
  const currency_options = self.find('.currency_options')
  self.clickover({
    title: 'Choose currency',
    content: currency_options.html(),
    html: true,
    placement: 'bottom',
    sanitize: false,
  })

  $(document).on(
    'click',
    'a[data-behavior=currency_field_currency_select]',
    function (e) {
      e.preventDefault()
      const link = $(e.target).closest('a')
      const value = link.data('value')
      const input_name = link.data('input-name')
      const input = self.closest('form').find(`input[name='${input_name}']`)
      input.val(value).change()
      // .clickover('hide') does hide it, but then it won't show again after
      link.closest('.clickover').click()
    }
  )
})
